const ScrollLinkEnum = Object.freeze({
    ABOUT: "about",
    TOKENOMICS: "tokenomics",
    TEAM: "team",
    ROADMAP: "roadmap",
    TRADE: "trade",
    SOCIALS: "socials",
    CONTRACT: "contract",
    FAQ: "faq",
    NONE: "NONE"
})

export default ScrollLinkEnum;