import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Balance = observer(() => {
    const { t } = useTranslation();
    const { chainStore, uiStore } = useStores();
    const { isCorrectWallet } = uiStore;
    const { walletBalance, connectToWallet, myDividends, claimDividends } = chainStore;
    const [balance, switchBalance] = useState(false);

    return (
        <>
            {!isCorrectWallet || !balance ?
                <div className={styles["balance__container"]} onClick={() => switchBalance(!balance)}>
                    <div className={styles["balance__connect"]} onClick={() => connectToWallet()} >
                        {t("purchase.connect")}
                    </div>
                </div>
                :
                <div onClick={() => switchBalance(!balance)} className={styles["balance__container--open"]}>
                    <div className={styles["balance__container--open-wrapper"]}>
                        <div className={styles["balance__text--open"]} >
                            {t("socials.close")}
                        </div>
                        <div className={styles["balance__block"]}>
                            <span className={styles["balance__block--sub-title"]}>{t("purchase.balance")}</span>
                            <span className={styles["balance__block--sub-text"]}>
                                {(walletBalance / 1000000000000000000).toFixed(4)}
                                {' ETH'}
                            </span>
                        </div>
                        <div className={styles["balance__block"]}>
                            <span className={styles["balance__block--sub-title"]}>DIVIDENDS</span>
                            <span className={styles["balance__block--sub-text"]}>
                                {(myDividends[3] / 1000000000000000000).toFixed(4)}
                                {' ETH'}
                            </span>
                        </div>
                        <div className={styles["ape-in-button"]} onClick={() => { claimDividends() }}>
                            CLAIM
                            </div>
                    </div>
                </div>

            }
        </>
    )
});

export default Balance;
