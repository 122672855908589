import { observer } from "mobx-react";
import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import styles from "./index.module.scss";

const Footer = observer(() => {
  return (
    <div className={styles["component-container"]}>
      <div className={styles["twitter-wrapper"]}>
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="honeybeetoken"
          options={{ height: 400 }}
        />
      </div>
    </div>
  )
});

export default Footer;
