import { observer } from "mobx-react";
import Moralis from "moralis";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Stickers = observer(() => {
    const { t } = useTranslation();
    const [buttonHovered, setButtonHovered] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [codeOne, setCodeOne] = useState(false);
    const [codeTwo, setCodeTwo] = useState(false);
    const [codeThree, setCodeThree] = useState(false);
    const [codeFour, setCodeFour] = useState(false);
    const [holders, setHolders] = useState(0);
    const [marketCap, setMarketCap] = useState(0);
    const [transactions, setTransactions] = useState(0);
    const [totalBurned, setTotalBurned] = useState(0);
    const serverUrl = "https://cgwkrkck2ssn.usemoralis.com:2053/server";
    const appId = "8rVUEYL0gz37a1VMcfUNuB5dPegmiv1Q13KcmZ7R";
    const { chainStore } = useStores();
    const { totalDividends } = chainStore;

    const fetchData = async () => {
        try {
            fetch("https://api.covalenthq.com/v1/1/tokens/0xe8936A5EF3F3d7D24d46984FD9D0b06270442ab5/token_holders/?quote-currency=USD&format=JSON&page-size=10000&key=ckey_8ef7f58cf2de484e81a07db7f23")
                .then(response => response.json())
                .then(data => setHolders(data.data.items.length));
           /* Moralis.start({ serverUrl, appId });
            const options = {
                address: "0xe8936A5EF3F3d7D24d46984FD9D0b06270442ab5",
                chain: "eth",
                exchange: "Uniswap"
            };
            const price = await ((await Moralis.Web3API.token.getTokenPrice(options)).usdPrice * 1000000).toFixed(3);
            setMarketCap(price);*/
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        fetchData();
        setInterval(() => {
            fetchData();
        }, 30000)
    }, [])

    return (
        <div id="about" className={styles["component-container"]}>
            <div data-aos-once="false" data-aos="fade-right" data-aos-delay="50"
                className={styles["sticker-container"]}>
                <div className={styles["row"]}>
                    <div className={styles["sticker"]}>
                        <div className={styles["sticker-1"]}>
                            {!codeOne ?
                                <div className={styles["sticker-text"]}>
                                    {t("stickers.mcap")}
                                    <span className={styles["sticker-description"]}>{marketCap}M</span>
                                </div>
                                :
                                <div className={styles["sticker-text"]}>
                                    UP UP
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles["sticker"]}>
                        <div className={styles["sticker-2"]}>
                            <div className={styles["sticker-text"]}>
                                {t("stickers.holders")}
                                <span className={styles["sticker-description"]}>{holders}</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles["sticker"]}>
                        <div className={styles["sticker-3"]}>
                            {!codeTwo ?
                                <div className={styles["sticker-text"]}>
                                    {t("stickers.transactions")}
                                    <span className={styles["sticker-description"]}>{totalDividends} ETH</span>
                                </div>
                                :
                                <div className={styles["sticker-text"]}>
                                    DOWN DOWN
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles["sticker"]}>
                        <div className={styles["sticker-4"]}>
                            <div className={styles["sticker-text"]}>
                                {t("stickers.burnedTokens")}
                                <span className={styles["sticker-description"]}>0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div data-aos-once="false" data-aos="fade-left" data-aos-delay="50"
                className={styles["feature-container"]}>
                <div className={styles["row"]}>
                    <div className={styles["feature"]}>
                        <div className={styles["feature-1"]}>
                            <div className={styles["feature-text"]}>
                                {t("stickers.feature1.title")}
                                <div className={styles["feature-line"]} />
                                <div className={styles["feature-text-description"]}>
                                    {t("stickers.feature1.description")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["feature"]}>
                        <div className={styles["feature-2"]}>
                            <div className={styles["feature-text"]} onClick={() => setCodeThree(!codeThree)}>
                                {t("stickers.feature2.title")}
                                <div className={styles["feature-line"]} />
                                {!codeThree ?
                                    <div className={styles["feature-text-description"]}>
                                        {t("stickers.feature2.description")}
                                    </div>
                                    :
                                    <div className={styles["feature-text-description"]}>
                                        LEFT RIGHT LEFT RIGHT
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles["feature"]}>
                        <div className={styles["feature-3"]}>
                            <div className={styles["feature-text"]}>
                                {t("stickers.feature3.title")}
                                <div className={styles["feature-line"]} />
                                <div className={styles["feature-text-description"]}>
                                    {t("stickers.feature3.description")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["feature"]}>
                        <div className={styles["feature-4"]}>
                            <div className={styles["feature-text"]} onClick={() => setCodeFour(!codeFour)}>
                                {t("stickers.feature4.title")}
                                <div className={styles["feature-line"]} />
                                {!codeFour ?
                                    <div className={styles["feature-text-description"]}>
                                        {t("stickers.feature4.title")}
                                    </div>
                                    :
                                    <div className={styles["feature-text-description"]}>
                                        B A
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default Stickers;
