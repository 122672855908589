import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";

const RoadmapNew = observer(() => {
    const { t } = useTranslation();
    const [buttonHovered, setButtonHovered] = useState(false);
    const roadmapArray = t("roadmap.blocks", { returnObjects: true });
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(1);
    const [rightArrowClicked, setRightArrowClicked] = useState(false);
    const [leftArrowClicked, setLeftArrowClicked] = useState(false);
    const [blockClass, setBlockClass] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <div id="roadmap" data-aos="zoom-in" data-aos-delay="50" className={styles["component-container"]}>
            <div className={styles["roadmap_wrapper"]}>
                <div className={styles["roadmap"]}>
                    {roadmapArray.map((block, i) => {
                        const descArray = t(`roadmap.blocks.${i}.description`, { returnObjects: true });
                        return (
                            <>
                                {
                                    i === currentIndex &&
                                    <>
                                        <div key={i} className={styles["roadmap_block"]}>
                                            <div className={styles["roadmap_title"]}>
                                                {block.title}
                                            </div>
                                            {descArray.length > 0 && <div className={styles["roadmap_content-wrapper"]}>
                                                <img className={i === 0 ? styles["roadmap_logo-on"] : styles["roadmap_logo-off"]} alt={"HoneyBee Logo"} src={i === 0 ? "images/vagina-with-dildo.svg" : "images/dildo1-01.svg"} />
                                                <div className={styles["roadmap_block-text-wrapper"]}>
                                                    <div className={styles["roadmap_description"]}>
                                                        <ul>
                                                            {descArray && descArray.map((desc, i) => {
                                                                return (
                                                                    <>
                                                                        <li key={i}>{desc}</li>
                                                                    </>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </>
                                }
                            </>
                        )
                    })}
                </div>
                <div className={styles["roadmap__nav-container"]}>
                    <div className={styles["roadmap__nav-line"]} />
                    <div className={styles["roadmap__nav-text-wrapper"]} >
                        <ul>
                            {roadmapArray.map((desc, i) => {
                                return (
                                    <li onClick={() => setCurrentIndex(i)} className={styles[i === currentIndex ? "roadmap__nav-text-wrapper-text-active" : "roadmap__nav-text-wrapper-text"]}>{desc.title}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default RoadmapNew;
