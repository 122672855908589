import { observer } from "mobx-react";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from "../../stores/RootStore";
import styles from "./index.module.scss";

const Modal = observer(() => {
    const { t } = useTranslation();
    const { uiStore } = useStores();
    const { modalOpen, setModalOpen } = uiStore;

    return (
        modalOpen &&
        <div className={styles["modal-container"]} onClick={() => setModalOpen(false)}>
            <div className={styles["modal-content"]}>
                <div className={styles["modal-container-x"]} onClick={() => setModalOpen(false)}>
                    ✖
                </div>
                {t("purchase_nfts.disclaimer")}
            </div>
        </div>
    )
});

export default Modal;