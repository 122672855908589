import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./index.module.scss";
import { useStores } from "../../stores/RootStore";

const Tokenomics = observer(() => {
    const { t } = useTranslation();
    const [buttonHovered, setButtonHovered] = useState(false);
    const { uiStore } = useStores();
    const { apeVaderBucketImage } = uiStore;

    return (

        <div data-aos="fade-up" id="tokenomics" className={styles["component-container"]}>
            <div className={styles["row"]}>
                <h2 data-aos="fade-up-left">
                    {t("tokenomics.title")}
                </h2>

                <div data-aos="fade-right" data-aos-delay="50"
                    className={styles["tokenomics-column-1"]}>
                    <div className={styles["tokenomic"]}>
                        {t("tokenomics.block1")}
                    </div>
                    <div className={styles["tokenomic"]}>
                        {t("tokenomics.block2")}
                    </div>
                    <div className={styles["tokenomic"]}>
                        {t("tokenomics.block3")}
                    </div>
                </div>
                <div data-aos="fade-left" data-aos-delay="50"
                    className={styles["tokenomics-column-2"]}>
                    <div className={styles["tokenomic"]}>
                        {t("tokenomics.block4")}
                    </div>
                    <div className={styles["tokenomic"]}>
                        {t("tokenomics.block5")}
                    </div>
                    <div className={styles["tokenomic"]}>
                        {t("tokenomics.block6")}
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-delay="50"
                    className={styles["tokenomics-row1"]}>
                    <div className={styles["tokenomic-long"]}>
                        <span>{t("tokenomics.block7")}</span>
                    </div>
                </div>
                <div data-aos="fade-up" data-aos-delay="50"
                    className={styles["tokenomics-row"]}>
                    <div className={styles["tokenomic-long"]}>
                        <span>{t("tokenomics.block9_1")}</span>
                        <span className={styles["tokenomic-long__description"]}>{t("tokenomics.block9_2")}</span>
                    </div>
                </div>
                <img data-aos="fade-up-left" src={"images/honeybee.svg"} className={styles["ape-with-bananas"]} />
                <a data-aos="fade-up-left" href="https://ethscan.com/address/0xe8936A5EF3F3d7D24d46984FD9D0b06270442ab5" className={styles["eth-explorer"]}>
                    {"Check ETH Explorer >"}
                </a>
            </div>
        </div>
    )
});

export default Tokenomics;
