import { observer } from "mobx-react";
import React, { useEffect } from 'react';
import styles from "./index.module.scss";
import { useStores } from "../../stores/RootStore";
import NotificationTypeEnum from "../../enums/notification-type-enum";

const Notification = observer(() => {
    const { uiStore } = useStores();
    const { notificationBar, setNotificationBarVisibility } = uiStore;
    const { isVisible, type, message } = notificationBar;

    const onClose = () => {
        setNotificationBarVisibility(false);
    }

    useEffect(() => {
        if (isVisible) {
            setInterval(() => {
                setNotificationBarVisibility(false)
            }, 5000);
        }
    }, [isVisible, setNotificationBarVisibility])

    return (
        <>
            {isVisible && (
                type === NotificationTypeEnum.SUCCESS ?
                    <div className={styles["notification-container__success"]}>
                        <div className={styles["notification-container__exit"]} onClick={() => onClose()}>
                            ✖
                        </div>
                        <div className={styles["notification-container__title"]}>
                            Success
                        </div>
                        <div className={styles["notification-container__description"]}>
                            {message}
                        </div>
                    </div>
                    :
                    <div className={styles["notification-container__error"]}>
                        <div className={styles["notification-container__exit"]} onClick={() => onClose()}>
                            ✖
                        </div>
                        <div className={styles["notification-container__title"]}>
                            Error
                        </div>
                        <div className={styles["notification-container__description"]}>
                            {message}
                        </div>
                    </div>)
            }
        </>
    );
});

export default Notification;