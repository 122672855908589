import { observer } from "mobx-react";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import ButtonTypeEnum from "../../enums/button-type-enum";
import ScrollLinkEnum from "../../enums/scroll-link-enum";
import { useStores } from "../../stores/RootStore";
import Button from "../button";
import styles from "./index.module.scss";
import { DarkIcon } from "../../assets/img/darkIcon.js";
import { LightIcon } from "../../assets/img/lightIcon.js";

const NavigationBar = observer(() => {
    const { t, i18n } = useTranslation();
    const [languageMenu, setLanguageMenu] = useState(true);
    const { uiStore, chainStore } = useStores();
    const { hamburgerMenuOpen, theme, switchHamburgerMenu, switchTheme, setScrollLink } = uiStore;
    const { connectToWallet } = chainStore;
    const [navbarBackground, setNavbarBackground] = useState("navbar-without-background");
    const [logo, setLogo] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 0) {
            setNavbarBackground("navbar-with-background");
            setLogo(true)
        } else {
            setNavbarBackground("navbar-without-background");
            setLogo(false)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    }, [handleScroll]);

    return (
        <>
            <div className={styles["navbar-container"]}>
                <div className={styles[navbarBackground]}>
                    <div className={styles["logo-container"]}>
                        <NavLink onClick={() => setScrollLink(ScrollLinkEnum.NONE)} to="/">
                            <img className={styles["logo"]} alt={"HoneyBee Logo"} src={"images/honeybee-logo.svg"} />
                        </NavLink >
                    </div>
                    <div className={styles["navbar"]}>
                        <div className={styles["links-container"]}>
                            <div className={styles["links"]}>
                                <div onClick={() => setScrollLink(ScrollLinkEnum.ABOUT)}>
                                    <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.about")} path="/#about" />
                                </div>
                                <div onClick={() => setScrollLink(ScrollLinkEnum.TOKENOMICS)}>
                                    <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.tokenomics")} path="/#tokenomics" />
                                </div>
                                <div onClick={() => setScrollLink(ScrollLinkEnum.TEAM)}>
                                    <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.team")} path="/#team" />
                                </div>
                                <div onClick={() => setScrollLink(ScrollLinkEnum.ROADMAP)}>
                                    <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.roadmap")} path="/#roadmap" />
                                </div>
                                <div onClick={() => setScrollLink(ScrollLinkEnum.TRADE)}>
                                    <Button type={ButtonTypeEnum.LINK1} label={t("navbar.trade")} path="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xe8936A5EF3F3d7D24d46984FD9D0b06270442ab5" />
                                </div>
                            </div>
                            <img className={styles["eth-logo"]} alt={"HoneyBee Logo"} src={"images/ethereum.svg"} />

                            <div className={styles["theme-wrapper"]}>
                                <div className={styles["hamburger-menu"]} onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); }}>
                                    {t("navbar.menu")}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                hamburgerMenuOpen ?
                    <div className={styles["hamburger-menu-dropdown"]}>
                        <div className={styles["responsive-links-container"]}>
                            <div onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.ABOUT) }}>
                                <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.about")} path="/#about" />
                            </div>
                            <div onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.TOKENOMICS) }}>
                                <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.tokenomics")} path="/#tokenomics" />
                            </div>
                            <div onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.TEAM) }}>
                                <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.team")} path="/#team" />
                            </div>
                            <div onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.ROADMAP) }}>
                                <Button type={ButtonTypeEnum.NAVLINK} label={t("navbar.roadmap")} path="/#roadmap" />
                            </div>
                            <div onClick={() => { switchHamburgerMenu(!hamburgerMenuOpen); setScrollLink(ScrollLinkEnum.TRADE) }}>
                                <Button type={ButtonTypeEnum.LINK1} label={t("navbar.trade")} path="" />
                            </div>
                        </div>
                    </div>
                    : null
            }
        </>
    )
});

export default NavigationBar;
