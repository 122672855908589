export const TRANSLATIONS_EN = {
    navbar: {
        menu: "MENU",
        about: "ABOUT",
        tokenomics: "TOKENOMICS",
        team: "TEAM",
        roadmap: "ROADMAP",
        trade: "TRADE",
        contract: "CONTRACT",
        connect: "CONNECT",
        faq: "FAQ"
    },
    header: {
        title: "Honey Bee Inu! Our dog thinks she is a bee! ",
        description: "Honey Bee Inu Is A Rug-Proof (Locked Liquidity) dividends distribution token built on the Ethereum Chain.",
        tokenTitle: "Honey Bee Inu Token for ETH Chain",
        tokenButton: "CLAIM TOKENS",
        buy: "BUY ON UNISWAP",
    },
    stickers: {
        mcap: "Market Cap",
        holders: "Holders",
        transactions: "Total Dividends",
        burnedTokens: "Tokens Burned",
        feature1: {
            title: "Go Honey Bee Inu!",
            description: "100% of pre-sale ETH raised goes into the Uniswap Liquidity Pool!"
        },
        feature2: {
            title: "Bees'Building the Hive",
            description: "We're giving away $HONEY tokens to HONEY holders to build out our Hive!"
        },
        feature3: {
            title: "Wildlife Enthusiasts!",
            description: "In discussions with some of the smartest beekeepers around the world!"
        },
        feature4: {
            title: "Community Driven!",
            description: "Key decisions of the future of this project decided by votes from the #HiveSquad!"
        },
    },
    purchase: {
        connect: "CONNECT",
        balance: "BALANCE",
        title: "BUY NOW",
        button: "PURCHASE"
    },
    socials: {
        title: "SOCIALS",
        close: "CLOSE"
    },
    tokenomics: {
        title: "Tokenomics",
        block1: "Total Supply 1,000,000,000,000 HONEY",
        block2: "10% allocated for pre-sale ",
        block3: "10% giveaways, marketing, influencers, NFT’s",
        block4: "100% of ETH raised added to Uniswap LP",
        block5: ".5% max buy for the first 5 minutes",
        block6: "2.5% supply max sell per wallet",
        block7: "10% tokens vested after 60 days to liquidity providers",
        block9_1: "15% Transaction fees",
        block9_2: "10% yield reflection to HONEY BEE INU holders,  5% marketing and development fund",
    },
    team: {
        title: "TEAM",
        members: [
            {
                image: "images/team/1.svg",
                name: "Yamai - Full Stack Developer",
                quote: "Building the Hive infrastructure",
            },
            {
                image: "images/team/2.svg",
                name: "0xShonI - Lead Designer",
                quote: "Design is nature",

                image: "images/team/3.svg",
                name: "Johnny - Chief Marketing Officer",
                quote: "In the Hive we trust, to influence we Musk",

            },
            {
                image: "images/team/4.svg",
                name: "Dominic Ares - Community Manager",
                quote: "Every little worker bee must be accounted for, Save the Queen!",

            },
            {
                image: "images/team/5.svg",
                name: "Jason - Product manager",
                quote: "Everything is possible if we work as one.",

            },
            {
                image: "images/team/6.svg",
                name: "Yves - Sales Manager",
                quote: "Hi, Im Yves.",

            },
            {
                image: "images/team/7.svg",
                name: "TunaCan - Solidity Developer",
                quote: "Will build smart contracts for subway tuna subs",

            },
            {
                image: "images/team/8.svg",
                name: "Brittany",
                quote: "Back to OnlyFans if we dump...",
            },
        ]
    },
    roadmap: {
        title: "ROADMAP",
        button1: "View Medium",
        button2: "Download PDF",
        blocks: [
            {
                title: "MARKETING",
                description: ["AMA (monthly)", "Cameos(Crypto Influencers, Rappers, celebrities, athletes)", "Promotional tweets", "Website banners", "Community medium articles", "Listing on CMC & CoinGecko", "Banner Ads on Dextools, CG,CMC, and even New York City in Times Square", "Partnership"]
            },
            {
                title: "COMMUNITY CARE",
                description: ["Form the Hive Army!", "Discord discussions", "Meme Contests", "Sticker Contests", "Rewards for community supporter"],
            },
            {
                title: "NFT’s",
                description: ["Form the Hive Army!"
                    , "Discord discussions"
                    , "Meme Contests"
                    , "Custom Stickers"
                    , "Rewards for all community supporters"],
            },
            {
                title: "People of the Hive",
                description: ["Autogenerated NFTs with random rarity based on cumulative power of each NFT layer"
                    , "Utilized NFTS:"
                    , "- Stake in DAO to acces DAO powers"
                    , "- Stake up to 5 NFTs and earn Honey Bee Inu, Games, NFT sales and other ecosystem profit share based on combine staking power of your NFTs"],
            },
            {
                title: "HoneyBeeGames",
                description: ["On chain games, “Bee & Burn” and more"
                    , "Spend and win $HONEY token"
                    , "Share of games profit for NFT stakers"],
            },
            {
                title: "HIVEDAO",
                description: ["Home of all hardcore Bees"
                    , "Share your ideas. Vote in important matters and earn"
                    , "from Ecosystem profit"],
            },
            {
                title: "HIVESWAP",
                description: ["The absolute stickiest swap platform where liquidity never leaves!"
                    , "The Hive will buy back and burn HONEY"
                    , "Share profit with DAO NFT stakers"],
            },
            {
                title: "HONEYSHOP",
                description: ["Branded merch"
                    , "Save the bees!, Honey themed items"
                    , "Profit share with $HONEY relfection"],
            },
            {
                title: "HIVEBRIDGE (CROSSCHAIN)",
                description: ["Arbitrum layer 2"
                    , "AVAX"
                    , "POLYGON"
                    , "MOONRIVER"]

            }
        ],
    }
}
