import { observer } from "mobx-react";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Konami from 'react-konami-code';
import styles from "./index.module.scss";
import { useStores } from "../../stores/RootStore";

const Header = observer(() => {
    const { t } = useTranslation();
    const [bananaPicture, setBananaPicture] = useState("images/banana-closed.svg");
    const [bananaClass, setBananaClass] = useState("banana-closed");
    const [apeVaderImage, setApeVaderImage] = useState("images/character-01.svg");
    const [moonImage, setMoonImage] = useState("images/honeybee-hive.svg");
    const [rocketImage, setRocketImage] = useState("images/rocket.svg");
    const [saberOn, setSaberOn] = useState(false);
    const [dildo, setDildo] = useState("images/banana.svg");
    const { uiStore } = useStores();
    const { setApeVaderBucketImage } = uiStore;

    const easterEgg = () => {
    }

    return (
        <>
            <Konami action={easterEgg} />
            <div id="header" data-aos-delay="50"
                className={styles["component-container"]}>
                <div className={styles["row"]}>
                    <div className={styles["column"]}>
                        <div className={styles["header-welcome-wrapper"]}>
                            <img data-aos="fade-right" className={styles["logo"]} src={"images/honeybee-text.svg"} alt="Honey Bee Inu logo" />
                            <div data-aos="fade-right" data-aos-delay="400" className={styles["welcome-message"]}>{t("header.title")}</div>
                            <div data-aos="fade-right" data-aos-delay="800" className={styles["welcome-description"]}>{t("header.description")}</div>
                            <div data-aos="fade-right" data-aos-delay="1200" className={styles["saving-bees"]}>1% of marketing funds quarterly to Saving Honey Bees across the world.</div>
                            <div data-aos="fade-right" data-aos-delay="1400" className={styles["token-contract"]}>
                                <span> {t("header.tokenTitle")}</span>
                                <span className={styles["token-address"]}>0xe8936A5EF3F3d7D24d46984FD9D0b06270442ab5</span>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="1600" className={styles["button-wrapper"]}>
                                <a className={styles["ape-in-button"]} href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xe8936A5EF3F3d7D24d46984FD9D0b06270442ab5">
                                    {t("header.buy")}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" data-aos-delay="1200" className={styles["column"]}>
                        <div className={styles["ape-wrapper"]}>
                            <img className={styles["honeybee"]} src={"images/honey-bee-inu.png"} alt="HoneyBee" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Header;
